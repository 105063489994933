<template>
  <div>
    <div class="operate">
      <el-page-header
        :content="$t('editAdmin.editAdminMsg')"
        @back="goBack"
      />
      <el-button
        style="marginTop:20px"
        type="primary"
        :loadi="editLoading"
        @click="confirmEditAdmin"
      >
        {{ $t("common.confirmEdit") }}
      </el-button>
    </div>
    <div class="card">
      <div class="base">
        <el-card>
          <div slot="header">
            {{ $t("editAdmin.adminMsg") }}
          </div>
          <el-form
            ref="adminForm"
            :model="adminForm"
            inline
            :rules="rules"
            label-width="120px"
          >
            <el-form-item
              :label="$t('common.username')"
              prop="LoginName"
            >
              <el-input
                v-model="adminForm.LoginName"
                disabled
              />
            </el-form-item>
            <el-form-item
              :label="$t('common.newpass')"
              prop="Password"
            >
              <el-input
                v-model="adminForm.Password"
                type="password"
                auto-complete="new-password"
                show-password
              />
            </el-form-item>
            <el-form-item
              :label="$t('editAdmin.role')"
              prop="RoleID"
            >
              <el-select
                v-model="adminForm.RoleID"
                :popper-append-to-body="false"
                :placeholder="$t('common.pleaseChoose')"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.RoleID"
                  :label="item.RoleName"
                  :value="item.RoleID"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('editAdmin.adminStatus')"
              prop="UserStatus"
            >
              <el-select
                v-model="adminForm.UserStatus"
                :popper-append-to-body="false"
                :placeholder="$t('common.pleaseChoose')"
              >
                <el-option
                  v-for="item in adminStatusList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('editAdmin.realName')"
              prop="RealName"
            >
              <el-input v-model="adminForm.RealName" />
            </el-form-item>
            <el-form-item
              :label="$t('common.email')"
              prop="Email"
            >
              <el-input v-model="adminForm.Email" />
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { get_all_roles } from '@/api/role.js'
import { update_admin } from '@/api/admin.js'
import { formatTime } from '@/common/util.js'
export default {
  data () {
    return {
      editLoading: false,
      adminForm: {
        LoginName: '',
        Password: '',
        RePassword: '',
        RoleID: '',
        UserStatus: '',
        Domain: '',
        GroupID: '',
        PowzaUserName: '',
        PowzaPassword: '',
        RealName: '',
        Email: '',
        Type: '1',
        Region: '0',
        Topology: '0',
        ChannelGroup: 'all'
      },
      rules: {
        LoginName: [{
          required: true, message: this.$t('addAdmin.inputUsername'), trigger: 'blur'
        }],
        RoleID: [{
          required: true, message: this.$t('addAdmin.chooseRole'), trigger: 'blur'
        }],
        UserStatus: [{
          required: true, message: this.$t('addAdmin.chooseAdminStatus'), trigger: 'blur'
        }]
      },
      roleList: [],
      adminStatusList: [{
        label: this.$t('common.normal'),
        value: 1
      },
      {
        label: this.$t('common.disable'),
        value: 2
      }]
    }
  },
  created () {
    this.getAllRoles()
  },
  mounted () {
    this.$nextTick(() => {
      this.adminForm = this.$route.params
      this.adminForm.Region = '0'
      this.adminForm.Topology = '0'
      this.adminForm.ChannelGroup = 'all'
    })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 确认修改
    confirmEditAdmin () {
      this.$refs.adminForm.validate((valid) => {
        if (valid) {
          this.$confirm(this.$t('common.isConfirmEdit'), this.$t('common.remind'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }).then(() => {
            this.editLoading = true
            this.adminForm.CreateTime = formatTime(this.adminForm.CreateTime)
            console.log(this.adminForm)
            update_admin(this.adminForm).then((res) => {
              if (res.data.code === 0) {
                this.$message.success(this.$t('common.changeSuccess'))
                this.$router.back()
              }
              this.editLoading = false
            })
          }).catch(() => {})
        }
      })
    },
    // 获取所有角色
    getAllRoles () {
      get_all_roles().then((res) => {
        if (res.data.code === 0) {
          this.roleList = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.operate {
  position: sticky;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #cecece;
  top: 0;
  left: 0;
  z-index: 100;
}
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 45%;
    margin-right: 20px;
    margin-top: 20px;
  }
  /deep/ .el-card .el-input__inner {
    padding-right: 0;
  }
}

</style>
